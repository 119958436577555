<template>
  <v-dialog
    persistent
    transition="dialog-bottom-transition"
    class="b-dialog"
    content-class="b-dialog-question"
    :max-width="350"
    :retain-focus="false"
    :value="dialog"
  >
    <v-form @submit.prevent="save" ref="form" lazy-validation v-model="valid">
      <v-card class="b-dialog-question-card">
        <div class="b-dialog-question-card--body">
          <h3 class="primary--text">Atualização de vigência</h3>
          <BaseDatePicker
            id="readjustmentEffectiveDate"
            name="readjustmentEffectiveDate"
            label="Data de vigência"
            required
            :rules="[required]"
            v-model="model"
          />
        </div>
        <div class="b-dialog-question-card--footer">
          <BaseButton
            id="btn-back"
            name="btn-back"
            outlined
            color="primary"
            title="Cancelar"
            @click="closeModal(false)"
          />
          <BaseButton
            id="btn-save"
            name="btn-save"
            type="submit"
            color="primary"
            title="Salvar"
            :disabled="!valid"
          />
        </div>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { rulesMixin } from '@/mixins/rules';
import { showMessage } from '@/helpers/messages';
import { ContractService } from '@/services/api/contracts';

export default {
  mixins: [rulesMixin],

  props: {
    dialog: {
      type: Boolean,
      default: false,
      required: true
    },
    customerId: {
      type: String
    },
    contractId: {
      type: String
    },
    capital: {
      type: Object,
      default: () => {}
    }
  },

  data: () => ({
    valid: true,
    model: null
  }),

  methods: {
    closeModal(search) {
      this.$emit('closeModal', search);
    },

    async save() {
      if (!this.$refs.form.validate(true)) {
        return;
      }

      try {
        let capital = Object.assign({}, this.capital);
        delete capital.effectiveDate;

        const contractService = new ContractService();
        const { status } = await contractService.updateLifeCapital({
          contractId: this.contractId,
          customerId: this.customerId,
          effectiveDate: this.model,
          ...capital
        });

        if (status === 204) {
          showMessage('success', 'Operação realizada com sucesso');
          this.closeModal(true);
        }
      } catch (error) {
        throw new Error(error);
      }
    }
  }
};
</script>
